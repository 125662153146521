import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G10Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g10Guid
      this.g10Guid = props.g10Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.materialListGuid = props.materialListGuid
      this.currentYearNum = props.currentYearNum
      this.month1 = props.month1
      this.month2 = props.month2
      this.month3 = props.month3
      this.month4 = props.month4
      this.month5 = props.month5
      this.month6 = props.month6
      this.month7 = props.month7
      this.month8 = props.month8
      this.month9 = props.month9
      this.month10 = props.month10
      this.month11 = props.month11
      this.month12 = props.month12
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
      this.year = props.year
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g10Guid) this.g10Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.materialListGuid) this.materialListGuid = ''
    if (!this.currentYearNum) this.currentYearNum = 0
    if (!this.month1) this.month1 = 0
    if (!this.month2) this.month2 = 0
    if (!this.month3) this.month3 = 0
    if (!this.month4) this.month4 = 0
    if (!this.month5) this.month5 = 0
    if (!this.month6) this.month6 = 0
    if (!this.month7) this.month7 = 0
    if (!this.month8) this.month8 = 0
    if (!this.month9) this.month9 = 0
    if (!this.month10) this.month10 = 0
    if (!this.month11) this.month11 = 0
    if (!this.month12) this.month12 = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.year) this.year = 0
  }

  generatePrimaryKey () {
    this.g10Guid = utility.getUuid()
    this.resourceId = this.g10Guid
  }
}
