const G10SpreadInfo = {
  sheetName: 'G10',
  headerRowCount: 2,
  headerColumnCount: 18,
  headerColumns: [
    {
      index: 0,
      name: '序号',
      width: '110'
    },
    {
      index: 1,
      name: '材料名称及规格',
      width: '100',
      children: [
        {
          index: 1,
          name: '工程部位',
          width: '150'
        },
        {
          index: 2,
          name: '材料名称',
          width: '200'
        }
      ]
    },
    {
      index: 3,
      name: '单位',
      width: '60'
    },
    {
      index: 4,
      name: '合同总用量',
      width: '90'
    },
    {
      index: 5,
      name: '本年计划用量',
      width: '90'
    },
    {
      index: 6,
      name: '1月',
      width: '60'
    },
    {
      index: 7,
      name: '2月',
      width: '60'
    },
    {
      index: 8,
      name: '3月',
      width: '60'
    },
    {
      index: 9,
      name: '4月',
      width: '60'
    },
    {
      index: 10,
      name: '5月',
      width: '60'
    },
    {
      index: 11,
      name: '6月',
      width: '60'
    },
    {
      index: 12,
      name: '7月',
      width: '60'
    },
    {
      index: 13,
      name: '8月',
      width: '60'
    },
    {
      index: 14,
      name: '9月',
      width: '60'
    },
    {
      index: 15,
      name: '10月',
      width: '60'
    },
    {
      index: 16,
      name: '11月',
      width: '60'
    },
    {
      index: 17,
      name: '12月',
      width: '60'
    }
  ]
}

export default G10SpreadInfo
